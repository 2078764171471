<template>
    <div class="container-fluid p-0">
        <div class="page-headermb">
            <h2 class="main-content-title">Account Status</h2>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="profile-related-tabs">
                    <sub-menu @change-menu="activeMenu = $event" />
                </div>
                <div class="profile-tab-content">
                    <div
                        v-if="activeMenu === '1'">
                        <personal />
                    </div>
                    <div
                        v-if="activeMenu === '2'">
                        <team />
                    </div>
                    <div
                        v-if="activeMenu === '3'">
                        <external-projects />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SubMenu from "@/components/accountStatus/SubMenu";
import Team from "@/components/accountStatus/Team";
import Personal from "@/components/accountStatus/Personal";
import ExternalProjects from "@/components/accountStatus/ExternalProjects";
export default {
    name: "Index",
    components: {
        ExternalProjects,
        Personal,
        Team,
        SubMenu
    },
    data() {
        return {
            activeMenu: "1"
        };
    }
}
</script>

<style scoped>

</style>