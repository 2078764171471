<template>
    <el-menu
        unique-opened
        default-active="1"
        class="el-menu-vertical-demo custom-submenu"
        @open="handleOpen"
        @close="handleClose">
        <el-menu-item @click="handleSelectMenu('1')" style="text-align: left; margin: 0;" index="1">
            <span slot="title">My Personal Projects</span>
        </el-menu-item>
        <el-menu-item @click="handleSelectMenu('2')" style="text-align: left; margin: 0;" index="2">
            <span slot="title">Team Projects</span>
        </el-menu-item>
        <el-menu-item @click="handleSelectMenu('3')" style="text-align: left; margin: 0;" index="3">
            <span slot="title">External Projects</span>
        </el-menu-item>
    </el-menu>
</template>

<script>
import Icons from "@/components/common/Icons";

export default {
    name: "SubMenu",
    components: {
        Icons
    },
    data() {
        return {
            selectedMenu: ""
        }
    },
    methods: {
        handleSelectMenu(menu) {
            this.selectedMenu = menu;
            this.$emit('change-menu', menu);
        },
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        },
    }
}
</script>

<style scoped>

</style>