<template>
    <div class="profile-content shadow mt-3">
        <h3 class="card-title mb-3">Account Status of Personal Projects</h3>
        <div class="no-data-box text-center text-danger border p-3 rounded" v-if="!projectCount === true && !project">
            No Account Status of Personal Projects Available
            <!-- <img class="img-fluid" src="../../assets/img/no-data-found.jpg" /> -->
        </div>
        <div v-if="project && projectCount === true" class="col-lg-6 col-md-6">
            <div class="table-responsive-md">
                <table class="table border m-0 status-table">
                    <tbody>
                        <tr>
                            <th><span>Storage</span></th>
                            <td>{{ project.storage_used }} GB / {{ project.storage }} GB</td>
                        </tr>
                        <tr>
                            <th><span>Pricing Plan</span></th>
                            <td>{{ project.plan_name }}</td>
                        </tr>
                        <tr>
                            <th><span>Active Projects</span></th>
                            <td>{{ project.active_projects }} out of {{ project.max_active_project }}</td>
                        </tr>
                        <tr>
                            <th><span>Past Projects</span></th>
                            <td>{{ project.past_projects }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {Notification} from "element-ui";

export default {
    name: "Personal",
    data() {
        return {
            project: null,
            projectCount: false,
            gridData: [{
                date: 'Active Projects',
                name: null,
            },{
              date: 'Draft Projects',
              name: null,
            }, {
                date: 'Past Projects',
                name: null,

            }, {
                date: 'Deleted Projects',
                name: null,

            }],

        }
    },
    methods :{
      getProject(){
        axios.get('/account-status/personal-plans').then((res)=>{
          this.project = res.data.data
          this.gridData[0].name = res.data.data.active_projects
          this.gridData[1].name = res.data.data.draft_projects
          this.gridData[2].name = res.data.data.past_projects
          this.gridData[3].name = res.data.data.deleted_projects
          this.projectCount = true
        }).catch((error)=>{
        //   Notification.error(`${error.response.data.message}`);
        })
      }
    },
  mounted() {
      this.getProject()
  }
}
</script>

<style scoped>

</style>