<template>
  <div class="profile-content shadow mt-3">
    <h3 class="card-title mb-3">Account Status of Team Projects</h3>
    <div class="no-data-box text-center text-danger border p-3 rounded" v-if="plans.length === 0 && planCount === true">
      No Account Status of Team Projects Available
      <!-- <img class="img-fluid" src="../../assets/img/no-data-found.jpg" /> -->
    </div>
    <div v-if="plans.length > 0 && planCount === true" class="col-lg-6 col-md-6"  >
      <div class="table-responsive-md">
        <table class="table border m-0 status-table">
          <thead>
          <tr>
            <th>Team</th>
            <th v-for="(plan, index) in plans" :key="index">{{ plan.name }}</th>
          </tr>
          </thead>
          <tbody>
          <tr class="border-bottom">
            <th><span>Storage</span></th>
            <td v-for="(plan, index) in plans" :key="index"><span v-if="plan.subscribed">
              {{ plan.storage_used }} GB / {{ plan.storage }} GB
            </span><span v-else>N/A</span></td>
          </tr>
          <tr class="border-bottom">
            <th><span>Team Members</span></th>
            <td v-for="(plan, index) in plans" :key="index"><span v-if="plan.subscribed">{{ plan.members }}</span><span v-else>N/A</span></td>
          </tr>
          <tr class="border-bottom">
            <th><span>Pricing Plan</span></th>
            <td v-for="(plan, index) in plans" :key="index"><span v-if="plan.subscribed">{{ plan.plan_name }}</span><span v-else class="text-danger">This team is not subscribed to any plan</span></td>
          </tr>
          <tr class="border-bottom">
            <th><span>Active Projects</span></th>
            <td v-for="(plan, index) in plans" :key="index"><span v-if="plan.subscribed">{{ plan.active_projects }}
              out of
              {{ plan.max_projects }}
            </span><span v-else>N/A</span></td>
          </tr>
          <tr class="border-bottom">
            <th><span>Past Projects</span></th>
            <td v-for="(plan, index) in plans" :key="index"><span v-if="plan.subscribed">{{ plan.past_projects }}</span><span v-else>N/A</span></td>
          </tr>
          <tr>
            <th><span>Team Created</span></th>
            <td v-for="(plan, index) in plans" :key="index">{{ plan.start_date }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {Notification} from "element-ui";

export default {
  name: "Team",
  data() {
    return {
      gridData: [{
        date: 'Active Projects',
        name: 4,
      }, {
        date: 'Draft Projects',
        name: 5,
      }, {
        date: 'Past Projects',
        name: 5,

      }, {
        date: 'Deleted Projects',
        name: 2,

      }],
      plans: [],
      planCount: false
    }
  },
  mounted() {
    this.getProject();
  },
  methods: {
    getProject() {
      axios.get('/account-status/team-plans').then((res) => {
        this.plans = res.data.data
        this.planCount = true
      }).catch((error) => {
        //Notification.error('Something went wrong, please try again later');
      })
    },
  },

}
</script>

<style scoped>

</style>