<template>
    <div class="profile-content shadow mt-3">
        <h3 class="card-title mb-3">Account Status of External Public Projects</h3>
        <div class="no-data-box text-center text-danger border p-3 rounded" v-if="!project && projectCount === true">
          No Account Status of External Public Projects Available
          <!-- <img class="img-fluid" src="../../assets/img/no-data-found.jpg" /> -->
        </div>
        <div v-if="project && projectCount === true" class="col-lg-6 col-md-6">
          <div class="table-responsive-md">
            <table class="table border m-0 status-table">
                  <tbody>
                  <tr>
                      <th><span>Active Projects</span></th>
                      <td>{{ project.active_projects }} out of {{ project.total_projects }}</td>
                  </tr>
                  <tr>
                      <th><span>Past Projects</span></th>
                      <td>{{ project.past_projects }}</td>
                  </tr>
                  <tr>
                      <th><span>Total Projects</span></th>
                      <td>{{ project.total_projects }}</td>
                  </tr>
                  </tbody>
              </table>
          </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {Notification} from "element-ui";

export default {
    name: "ExternalProjects",
    data() {
        return {
          gridData: [{
            date: 'Active Projects',
            name: null,
          },{
            date: 'Draft Projects',
            name: null,
          }, {
            date: 'Past Projects',
            name: null,

          }, {
            date: 'Deleted Projects',
            name: null,

          }],
          project: null,
          projectCount: false,
        }
    },
  methods :{
    getProject(){
      axios.get('/account-status/account-external').then((res)=>{
        this.project = res.data.data
        this.gridData[0].name = res.data.data.active_projects
        this.gridData[1].name = res.data.data.draft_projects
        this.gridData[2].name = res.data.data.past_projects
        this.gridData[3].name = res.data.data.deleted_projects
        this.projectCount = true
      }).catch((error)=>{
        // Notification.error('Something went wrong, please try again later');
      })
    }
  },
  mounted() {
    this.getProject()
  }
}
</script>

<style scoped>
.sidebar-title {
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #092C4C;
}
</style>